.zwi-book-menu {
  display: grid;
  grid-template-areas: "old new";
  width: 610px;
}

.zwi-book-menu__side {
  flex: 1 1 50%;
  flex-direction: column;
  flex-wrap: wrap;
  height: 400px;
}

.zwi-book-menu__old-testament {
  grid-area: old;
}

.zwi-book-menu__new-testament {
  grid-area: new;
}

.zwi-book-menu__books {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 400px;
}

.zwi-book-menu__item {
  cursor: pointer;
  &:hover {
    font-weight: bold;
  }
}
