.mat-mdc-form-field {
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
  .mat-form-field-prefix,
  .mat-form-field-suffix {
    .mat-reference-picker-toggle-default-icon {
      display: block;
      width: 1.5em;
      height: 1.5em;
    }

    .mat-mdc-icon-button .mat-reference-picker-toggle-default-icon {
      margin: auto;
    }
  }
}
