@import '../../../../../styles/tools/layout-common';

$zwi-reference-padding: 8px !default;
$zwi-reference-header-divider-width: 1px !default;
$zwi-reference-controls-vertical-padding: 3%;
$zwi-reference-controls-side-margin: calc(33% / 7 - 16px);

$zwi-reference-arrow-size: 5px !default;;
$zwi-reference-arrow-disabled-opacity: 0.5 !default;

$zwi-reference-prev-next-icon-border-width: 2px;
$zwi-reference-prev-next-icon-margin: 15.5px;
$zwi-reference-prev-icon-transform: translateX(2px) rotate(-45deg);
$zwi-reference-next-icon-transform: translateX(-2px) rotate(45deg);

.zwi-reference {
  display: flex;
  flex-flow: column;
}

.zwi-reference-header {
  padding: $zwi-reference-padding $zwi-reference-padding 0 $zwi-reference-padding;
}

.zwi-reference-footer {
  padding: $zwi-reference-padding $zwi-reference-padding 0 $zwi-reference-padding;
}

.zwi-reference-content {
  flex: 1;
  padding: 0 $zwi-reference-padding $zwi-reference-padding $zwi-reference-padding;
  outline: none;
  overflow-y: auto;
}

.zwi-reference-controls {
  display: flex;
  margin: $zwi-reference-controls-vertical-padding $zwi-reference-controls-side-margin;
}

.zwi-reference-spacer {
  flex: 1 1 auto;
}

.zwi-reference-period-button {
  min-width: 0;
}

.zwi-reference-arrow {
  display: inline-block;
  width: 0;
  height: 0;
  border-left: $zwi-reference-arrow-size solid transparent;
  border-right: $zwi-reference-arrow-size solid transparent;
  border-top-width: $zwi-reference-arrow-size;
  border-top-style: solid;
  margin: 0 0 0 $zwi-reference-arrow-size;
  vertical-align: middle;

  &.zwi-reference-arrow-invert {
    transform: rotate(180deg);
  }

  [dir='rtl'] & {
    margin: 0 $zwi-reference-arrow-size 0 0;
  }
}

.zwi-reference-previous-button,
.zwi-reference-next-button {
  position: relative;

  &::after {
    @include fill;
    content: '';
    margin: $zwi-reference-prev-next-icon-margin;
    border: 0 solid currentColor;
    border-top-width: $zwi-reference-prev-next-icon-border-width;

    [dir='rtl'] & {
      transform: rotate(180deg);
    }
  }
}

.zwi-reference-previous-button::after {
  border-left-width: $zwi-reference-prev-next-icon-border-width;
  transform: $zwi-reference-prev-icon-transform;
}

.zwi-reference-next-button::after {
  border-right-width: $zwi-reference-prev-next-icon-border-width;
  transform: $zwi-reference-next-icon-transform;
}

.zwi-reference-table {
  border-spacing: 0;
  border-collapse: collapse;
  width: 100%;
}

.zwi-reference-table-header th {
  padding: 0 0 $zwi-reference-padding 0;
  text-align: center;
}

.zwi-reference-table-header-divider {
  position:relative;
  height: $zwi-reference-header-divider-width;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: -$zwi-reference-padding;
    right: -$zwi-reference-padding;
    height: -$zwi-reference-header-divider-width;
  }
}
