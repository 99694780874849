@use '@angular/material' as mat;

$zwi-reference-picker-reference-padding: 8px;
$zwi-reference-picker-non-touch-reference-cell-size: 44px;
$zwi-reference-picker-non-touch-reference-width:
  $zwi-reference-picker-non-touch-reference-cell-size * 10 + $zwi-reference-picker-reference-padding;
$zwi-reference-picker-non-touch-reference-height: 454px;

$zwi-reference-picker-touch-landscape-width: 64vh;
$zwi-reference-picker-touch-landscape-height: 80vh;
$zwi-reference-picker-touch-portrait-width: 80vh;
$zwi-reference-picker-touch-portrait-height: 100vh;
$zwi-reference-picker-touch-min-width: 250px;
$zwi-reference-picker-touch-min-height: 312px;
$zwi-reference-picker-touch-max-width: 750px;
$zwi-reference-picker-touch-max-height: 788px;

.zwi-reference-picker-content {
  @include mat.elevation(8);

  display: block;
  border-radius: 2px;

  .zwi-reference {
    height: $zwi-reference-picker-non-touch-reference-height;
    overflow-y: auto;
    width: $zwi-reference-picker-non-touch-reference-width;
  }
}

.zwi-reference-picker-content-touch {
  @include mat.elevation(0);

  display: block;
  max-height: 80vh;
  overflow: auto;
  margin: -24px;

  .zwi-reference {
    min-width: $zwi-reference-picker-touch-min-width;
    min-height: $zwi-reference-picker-touch-min-height;
    max-width: $zwi-reference-picker-touch-max-width;
    max-height: $zwi-reference-picker-touch-max-height;
  }
}

@media all and (orientation: landscape) {
  .zwi-reference-picker-content-touch .zwi-reference {
    height: $zwi-reference-picker-touch-landscape-height;
    width: $zwi-reference-picker-touch-landscape-width;
  }
}

@media all and (orientation: portrait) {
  .zwi-reference-picker-content-touch .zwi-reference {
    height: $zwi-reference-picker-touch-portrait-height;
    width: $zwi-reference-picker-touch-portrait-width;
  }
}
