@use '@angular/cdk';

$zwi-reference-body-label-padding-start: 5% !default;
$zwi-reference-body-label-side-padding: 33% / 10 !default;
$zwi-refernce-body-cell-margin: 1px;
$zwi-reference-body-cell-min-size: 32px !default;
$zwi-reference-body-cell-content-margin: 5% !default;
$zwi-reference-body-cell-content-border-width: 1px !default;

$zwi-reference-body-min-size: 10 * $zwi-reference-body-cell-min-size !default;
$zwi-reference-body-cell-content-size: 100% - $zwi-reference-body-cell-content-margin * 2 !default;

.zwi-reference-body {
  min-width: $zwi-reference-body-min-size;
}

.zwi-reference-body-label {
  height: 0;
  line-height: 0;
  text-align: left;
  padding-left: $zwi-reference-body-label-side-padding;
  padding-right: $zwi-reference-body-label-side-padding;
}

.zwi-reference-body-cell {
  position: relative;
  height: 0;
  line-height: 0;
  text-align: center;
  margin: $zwi-refernce-body-cell-margin 0;
  outline: none;
  cursor: pointer;
  user-select: none;
}

.zwi-reference-body-disabled {
  cursor: default;
}

.zwi-reference-body-cell-content {
  position: absolute;
  top: $zwi-reference-body-cell-content-margin;
  left: $zwi-reference-body-cell-content-margin;

  display: flex;
  align-items: center;
  justify-content: center;

  box-sizing: border-box;
  width: $zwi-reference-body-cell-content-size;
  height: $zwi-reference-body-cell-content-size;

  line-height: 1;

  border-width: $zwi-reference-body-cell-content-border-width;
  border-style: solid;

  border-radius: 999px;

  @include cdk.high-contrast() {
    border: none;
  }
}

@include cdk.high-contrast() {
  .zwi-reference-picker-popup,
  .zwi-reference-body-selected {
    outline: solid 1px;
  }

  .zwi-reference-body-today {
    outline: dotted 1px;
  }
}


[dir='rtl'] {
  .zwi-reference-body-label {
    text-align: right;
  }
}


